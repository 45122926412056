export default function Complete() {
    return <>
        <div className="content">
            <div className="terminal-container">
                <div className="terminal">
                    <h1>YOU'RE DONE!</h1>
                    <p style={{color: "white"}}>Good job!</p>
                </div>
            </div>
        </div>
    </>
}