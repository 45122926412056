export default function Challenge1() {
    return <>
        <div className="content">
            <div className="terminal-container">
                <div className="terminal">
                    <p style={{display: "none"}} className="the-classic-caeser-cipher"></p>
                    <h1 className="oanajzelepu" id="shift-by-4">ET TU, BRUTE?</h1>
                </div>
            </div>
        </div>
    </>
}